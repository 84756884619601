import { ChainId, Currency } from '@madmeerkatfinance/sdk'
import { BinanceIcon, TokenLogo } from '@madmeerkatfinance/uikit'
import { useMemo } from 'react'
import { WrappedTokenInfo } from '@madmeerkatfinance/token-lists'
import styled from 'styled-components'
import { useHttpLocations } from '@madmeerkatfinance/hooks'
import getTokenLogoURL from '../../utils/getTokenLogoURL'

const StyledLogo = styled(TokenLogo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency?.isNative) return []

    if (currency?.isToken) {
      const tokenLogoURL = getTokenLogoURL(currency)

      if (currency instanceof WrappedTokenInfo) {
        if (!tokenLogoURL) return [...uriLocations]
        return [...uriLocations, tokenLogoURL]
      }
      if (!tokenLogoURL) return []
      return [tokenLogoURL]
    }
    return []
  }, [currency, uriLocations])

  if (currency?.isNative) {
    if (currency.chainId === ChainId.ARBITRUM) {
      return (
        <StyledLogo
          size={size}
          srcs={[`/images/${currency.chainId}/tokens/0x82aF49447D8a07e3bd95BD0d56f35241523fBab1.svg`]}
          width={size}
          style={style}
        />
      )
    }
    if (currency.chainId === ChainId.BSC) {
      return <BinanceIcon width={size} style={style} />
    }
    return <StyledLogo size={size} srcs={[`/images/chains/${currency.chainId}.png`]} width={size} style={style} />
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
